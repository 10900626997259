import { Buffer } from 'buffer';
import S3 from 'relata-react-aws-s3';

import { s3BucketName } from '@/constants/urls';

// class S3 {
//   constructor(props: any) {}

//   uploadFile(prop1: any, prop2: any) {}
// }

window.Buffer = window.Buffer || Buffer;

const REGION = '56565757';
const ACCESS_KEY_ID = '1234';
const SECRET_ACCESS_KEY = '1234578789';

class S3ClientNew {
  static fileName(url: string) {
    return url?.substring(url?.lastIndexOf('/') + 1);
  }

  static uploadFile(
    file: File,
    fileName: string,
    s3DirName: string,
    bucketName: string = s3BucketName
  ) {
    const config = {
      bucketName,
      dirName: s3DirName,
      // TODO: Fetch secrete key from env
      region: REGION,
      accessKeyId: ACCESS_KEY_ID,
      secretAccessKey: SECRET_ACCESS_KEY,
    };
    const ReactS3Client = new S3(config);
    return ReactS3Client.uploadFile(file, fileName);
  }
}

export default S3ClientNew;
