import { adminAPISlice } from '@/admin/services';
import { IResponseBase } from '@/interfaces';

const fileUploadAPISlice = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    registerSignedUrl: build.mutation<TSignedUrlResponse, TSignedUrlArgs>({
      query: (queryArg) => ({
        url: `/api/signed-url`,
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
});

export const { useRegisterSignedUrlMutation } = fileUploadAPISlice;

export type TSignedUrlArgs = {
  maxFileSizeInKiB: number;
  ttlInSeconds: number;
  fileType: string;
  purpose: string;
  fileName: string;
  folderName: string;
};

type TSignedUrlResponse = IResponseBase & {
  data: string;
};
