import {
  TSignedUrlArgs,
  useRegisterSignedUrlMutation,
} from '@/admin/services/fileUploadAPISlice';
import useToast from '@/hooks/useToast';
import { IErrorResponse } from '@/interfaces';

export const useHandleUpload = () => {
  const [registerSignedUrlAPI] = useRegisterSignedUrlMutation();
  const [addToast] = useToast();

  const createPendingFile = (file: File): FileObject => ({
    id: crypto.randomUUID(),
    name: file.name,
    file,
    status: 'Pending',
    url: '',
  });

  const showToast = (
    type: 'ERROR' | 'SUCCESS',
    primaryMessage: string,
    secondaryMessage?: string
  ) => {
    addToast({
      type,
      primaryMessage,
      secondaryMessage,
      timeout: 1000,
    });
  };

  const uploadFileToSignedUrl = async (
    file: File,
    signedUrl: string
  ): Promise<string> => {
    const formData = new FormData();
    const parsedSignedUrl = new URL(signedUrl);

    parsedSignedUrl.searchParams.forEach((value, key) => {
      formData.append(key, value);
    });
    formData.append('file', file);

    const uploadResponse = await fetch(signedUrl.split('?')[0], {
      method: 'POST',
      body: formData,
    });

    if (!uploadResponse.ok) {
      throw new Error(`Upload failed with status ${uploadResponse.status}`);
    }

    return signedUrl.split('?')[0] + parsedSignedUrl.searchParams.get('key');
  };

  const handleParallelFileUpload = async ({
    fileArray,
    folderName,
  }: {
    fileArray: File[];
    folderName: string;
  }) => {
    const fileUploadPromises = fileArray.map(async (file) => {
      const newFile = createPendingFile(file);
      const exentension = file.name.split('.').pop();
      const args: TSignedUrlArgs = {
        maxFileSizeInKiB: 10000,
        ttlInSeconds: 100,
        fileType: file.type,
        purpose: 'upload',
        folderName,
        fileName: `${crypto.randomUUID()}.${exentension}`,
      };
      try {
        const { data: signedUrl } = await registerSignedUrlAPI(args).unwrap();
        const uploadedUrl = await uploadFileToSignedUrl(file, signedUrl);
        newFile.url = uploadedUrl;
        newFile.status = 'Success';
      } catch (error) {
        newFile.status = 'Error';

        const secondaryMessage = (error as IErrorResponse)?.data?.message;
        showToast('ERROR', 'Failed to upload file', secondaryMessage);
      }

      return newFile;
    });

    return Promise.all(fileUploadPromises);
  };

  return { handleParallelFileUpload };
};

type FileObject = {
  id: string;
  name: string;
  file: File;
  url: string;
  status: 'Pending' | 'Success' | 'Error';
};
