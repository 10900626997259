import {
  IDistute,
  TDisputeReq,
} from '@/admin/components/pages/CMDashboard/pageBodies/MainPageBody/LeadDetails';
import { LEADS_TAB } from '@/admin/components/pages/ProjectAllLeads';
import { TProjectLead } from '@/admin/components/pages/ProjectAllLeads/LeadList';
import { IDirectSiteVisit } from '@/admin/components/pages/ProjectDirectSitevisits/DirectSiteVisits';
import { TFollowupsTab } from '@/admin/components/pages/ProjectLeadFollowups';
import { PARTNER_LEAD_TABS } from '@/admin/components/pages/ProjectPartnerLeads';
import {
  IActiveLead,
  ISiteVisit,
} from '@/admin/components/pages/ProjectPartnerPreLeads';
import { IBookLeadReq } from '@/admin/components/pages/ProjectPartnerPreLeads/modals/BookingFormModal';
import { IProjectPartnerSitevisitsReq } from '@/admin/components/pages/ProjectPartnerSitevisits/PartnerSiteVisits';
import { adminAPISlice } from '@/admin/services/index';
import type { IProjectLead, IProjectUnitLead } from '@/cp/interfaces';
import type { ICpLead, IResponseBase, IUnit, TObjectId } from '@/interfaces';
import type {
  ITransformCountResponse,
  ITransformCpLeadsResponse,
  ITransformResponse,
} from '@/interfaces/services';

export const projectLeadsAPI = adminAPISlice.injectEndpoints({
  endpoints: (build) => ({
    getProjectLeadsByAdminUserId: build.query<
      IProjectLead[],
      TObjectId | undefined
    >({
      query: (adminUserId: TObjectId) => `api/projectLeads/${adminUserId}`,
      transformResponse: (response: ITransformProjectLeadsResponse) => {
        return response.data;
      },
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.map(({ id }) => ({ type: 'Projects' as const, id })),
      //         { type: 'Projects', id: 'LIST' },
      //       ]
      //     : [{ type: 'Projects', id: 'LIST' }],
    }),

    getProjectCPLeadsByProjectId: build.query<ICpLead[], TObjectId>({
      query: (projectId: TObjectId) =>
        `api/users/cp/cp-lead/project/${projectId}`,
      transformResponse: (response: ITransformCpLeadsResponse) => {
        return response.data;
      },
    }),

    getProjectSiteVisitLeadsByProjectId: build.query<ICpLead[], TObjectId>({
      query: (projectId: TObjectId) => `api/projectLeads/project/${projectId}`,
      transformResponse: (response: ITransformCpLeadsResponse) => {
        return response.data;
      },
    }),

    getProjectVSGLeadsByProjectId: build.query<ICpLead[], TObjectId>({
      query: (projectId: TObjectId) => `api/projects/${projectId}/leads`,
      transformResponse: (response: ITransformCpLeadsResponse) => {
        return response.data;
      },
    }),

    getProjectPartnerActiveLeads: build.mutation({
      query: ({
        projectId,
        pageNumber,
        pageCount,
        executiveId,
        searchField = '',
        searchName = '',
        startDate = '',
        endDate = '',
      }: {
        projectId: TObjectId;
        pageNumber: number;
        pageCount: number;
        executiveId: TObjectId;
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/projects/${projectId}/active-leads?executiveId=${executiveId}&searchField=${searchField}&searchName=${searchName}&pageCount=${pageCount}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
    }),

    getProjectPartnerSiteVisitsByProjectId: build.mutation({
      query: ({
        pageCount,
        pageNumber,
        executiveId,
        searchField = '',
        searchName = '',
        projectId,
        startDate = '',
        endDate = '',
        status,
      }: {
        projectId: TObjectId;
        pageNumber: number;
        pageCount: number;
        executiveId: TObjectId;
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
        status: string;
      }) => ({
        url: `api/cpLeadSiteVisit/project/${projectId}/${status}?executiveId=${executiveId}&pageCount=${pageCount}&pageNumber=${pageNumber}&searchField=${searchField}&searchName=${searchName}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
    }),
    getProjectPartnerSiteVisitsCount: build.query<
      number,
      {
        projectId: TObjectId;
        status: string;
        executiveId: TObjectId;
      }
    >({
      query: ({
        projectId,
        status,
        executiveId,
      }: {
        projectId: TObjectId;
        status: string;
        executiveId: TObjectId;
      }) =>
        `api/cpLeadSiteVisit/project/${projectId}/${status}/count?executiveId=${executiveId}`,
      transformResponse: (response: ITransformCountResponse) => {
        return response.data;
      },
    }),
    // IPartnerSitevisitsReq
    getAllProjectPartnersSiteVisitsCount: build.query<
      // FIXME: ts table
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<string, any>,
      IProjectPartnerSitevisitsReq
    >({
      queryFn: async (
        projectPartnersSiteVisitReq,
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const results = await Promise.all(
          projectPartnersSiteVisitReq.map((req) => {
            const { projectId, status, executiveId, filterPayload } = req;
            return fetchWithBQ({
              url: `api/cpLeadSiteVisit/project/${projectId}/${status}/count`,
              method: 'GET',
              params: { executiveId, ...filterPayload },
            });
          })
        );

        if (results.some((result) => result.error)) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              error: 'Some Error Occured',
            },
          };
        }

        return {
          data: projectPartnersSiteVisitReq
            .map((req, index) => ({
              [req.status]: results[index].data,
            }))
            .reduce(
              (obj, item) => ({
                ...obj,
                ...item,
              }),
              {}
            ),
        };
      },
    }),

    // TODO: need to remove this
    getProjectDirectSiteVisitsByProjectId: build.mutation({
      query: ({
        pageCount,
        pageNumber,
        searchField = '',
        searchName = '',
        projectId,
        startDate = '',
        endDate = '',
      }: {
        projectId: TObjectId;
        pageNumber: number;
        pageCount: number;
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/cpLeadSiteVisit/directSiteVisit/${projectId}?pageCount=${pageCount}&pageNumber=${pageNumber}&searchField=${searchField}&searchName=${searchName}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
    }),
    getProjectDirectSiteVisitsCount: build.query<
      number,
      {
        projectId: TObjectId;
        filterPayload: Record<string, string>;
      }
    >({
      query: ({ projectId, filterPayload }) => ({
        url: `api/cpLeadSiteVisit/directSiteVisit/${projectId}/count`,
        params: filterPayload,
      }),
      transformResponse: (response: ITransformCountResponse) => {
        return response.data;
      },
    }),

    getProjectPartnerRegisteredLeads: build.mutation({
      query: ({
        projectId,
        executiveId,
        pageNumber,
        pageCount,
        searchField = '',
        searchName = '',
        startDate = '',
        endDate = '',
      }: {
        projectId: TObjectId;
        executiveId: TObjectId;
        pageNumber: number;
        pageCount: number;
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/projects/${projectId}/register-leads?executiveId=${executiveId}&searchField=${searchField}&searchName=${searchName}&pageCount=${pageCount}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
    }),

    getProjectPartnerBookedLeads: build.mutation({
      query: ({
        projectId,
        pageNumber,
        pageCount,
        executiveId,
        searchField = '',
        searchName = '',
        startDate = '',
        endDate = '',
      }: {
        projectId: TObjectId;
        pageNumber: number;
        pageCount: number;
        executiveId: TObjectId;
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/projects/${projectId}/booked-leads?executiveId=${executiveId}&searchField=${searchField}&searchName=${searchName}&pageCount=${pageCount}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
    }),

    getProjectInventoryByProjectId: build.query<IUnit[], TObjectId>({
      query: (projectId: TObjectId) => `api/units/get-all/project/${projectId}`,
      transformResponse: (response: ITransformUnitsResponse) => {
        return response.data;
      },
    }),

    updateActivetoBookedLeads: build.mutation({
      query: ({
        requestPayload,
        projectId,
      }: {
        requestPayload: IBookLeadReq;
        projectId: TObjectId;
      }) => ({
        url: `api/projects/${projectId}/book-lead`,
        method: 'POST',
        body: requestPayload,
      }),
    }),

    updateSmtoUnAssignedPartner: build.mutation({
      query: ({
        projectId,
        smId,
        cpFirmId,
        cpUserId,
        tabName,
      }: {
        projectId: TObjectId;
        smId: TObjectId;
        cpFirmId: TObjectId;
        cpUserId: TObjectId;
        tabName?: 'active' | 'register' | 'unassigned';
      }) => ({
        url: `api/projects/${projectId}/sm/${smId}/cpFirm/${cpFirmId}/tagging?cpUserId=${cpUserId}&tabName=${tabName}`,
        method: 'POST',
      }),
    }),

    getProjectLeadUnitsByProjectId: build.query<IProjectUnitLead[], TObjectId>({
      query: (projectId: TObjectId) =>
        `api/units/unitCart/project/${projectId}`,
      transformResponse: (response: ITransformLeadUnitsResponse) => {
        return response.data;
      },
    }),

    updateBookedToRegistrationDone: build.mutation({
      query: ({
        requestPayload,
        projectId,
      }: {
        requestPayload: IBookLeadReq;
        projectId: TObjectId;
      }) => ({
        url: `api/projects/${projectId}/registration-done`,
        method: 'POST',
        body: requestPayload,
      }),
    }),

    getBookedToRegisteredLeads: build.mutation({
      query: ({
        projectId,
        pageNumber,
        pageCount,
        executiveId,
        searchField = '',
        searchName = '',
        startDate = '',
        endDate = '',
      }: {
        projectId: TObjectId;
        pageNumber: number;
        pageCount: number;
        executiveId: TObjectId;
        searchField?: string;
        searchName?: string;
        startDate?: string;
        endDate?: string;
      }) => ({
        url: `api/projects/${projectId}/registrations-done?executiveId=${executiveId}&searchField=${searchField}&searchName=${searchName}&pageCount=${pageCount}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
      }),
    }),
    getPartnerLeadsCount: build.query<
      // FIXME: ts table
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<string, any>,
      Array<IGetPartnerLeadsCountReq>
    >({
      queryFn: async (
        partnerLeadsCount,
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const results = await Promise.all(
          partnerLeadsCount.map((req) => {
            const { projectId, tabName, executiveId, isC45, ...rest } = req;
            return fetchWithBQ({
              url: `api/projects/${projectId}/${tabName}/count`,
              method: 'GET',
              params: {
                isC45,
                executiveId,
                ...rest,
              },
            });
          })
        );

        if (results.some((result) => result.error)) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              error: 'Some Error Occured',
            },
          };
        }

        return {
          data: partnerLeadsCount
            .map((req, index) => ({
              [req.tabName]: results[index].data,
            }))
            .reduce(
              (obj, item) => ({
                ...obj,
                ...item,
              }),
              {}
            ),
        };
      },
    }),

    updateDispute: build.mutation({
      query: ({
        requestPayload,
        projectLeadId,
      }: {
        requestPayload: IDistute;
        projectLeadId: TObjectId;
      }) => ({
        url: `api/projectLeads/${projectLeadId}/dispute`,
        method: 'PUT',
        body: requestPayload,
      }),
    }),
    addProjectLeadDispute: build.mutation({
      query: ({
        requestPayload,
        projectLeadId,
      }: {
        requestPayload: TDisputeReq;
        projectLeadId: TObjectId;
      }) => ({
        url: `api/disputes/${projectLeadId}`,
        method: 'POST',
        body: requestPayload,
      }),
    }),

    addProjectLead: build.mutation<
      { data: IProjectLead },
      { requestPayload: IProjectLeadReq }
    >({
      query: ({ requestPayload }) => ({
        url: 'api/projectLeads',
        method: 'POST',
        body: requestPayload,
      }),
    }),

    updateProjectLead: build.mutation<
      { data: IProjectLead },
      { projectLeadId: TObjectId; requestPayload: IUpdateProjectLeadReq }
    >({
      query: ({ requestPayload, projectLeadId }) => ({
        url: `api/projectLeads/${projectLeadId}`,
        method: 'PUT',
        body: requestPayload,
      }),
    }),

    getProjectLeadFollowupsCount: build.query<
      // FIXME: add type
      // FIXME: ts table
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<string, any>,
      TGetProjectLeadFollowupsCountReq
    >({
      queryFn: async (
        followupsCountReq,
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const results = await Promise.all(
          followupsCountReq.map((req) => {
            const { projectId, filterPayload, tabName, executiveId } = req;
            return fetchWithBQ({
              url: `api/followup/${projectId}/count`,
              method: 'GET',
              params: {
                tabName,
                executiveId,
                ...filterPayload,
              },
            });
          })
        );

        if (results.some((result) => result.error)) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              error: 'Some Error Occured',
            },
          };
        }
        return {
          data: followupsCountReq
            .map((req, index) => ({
              [req.tabName]: results[index].data,
            }))
            .reduce(
              (obj, item) => ({
                ...obj,
                ...item,
              }),
              {}
            ),
        };
      },
    }),

    getProjectLeadsCount: build.query<
      // FIXME: add type
      // FIXME: ts table
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Record<string, any>,
      TGetProjectLeadsCountReq
    >({
      queryFn: async (leadsCount, _queryApi, _extraOptions, fetchWithBQ) => {
        const results = await Promise.all(
          leadsCount.map((req) => {
            const {
              projectId,
              filterPayload,
              tabName,
              executiveId,
              isPartnerLead,
            } = req;
            return fetchWithBQ({
              url: `api/projectLeads/all-leads/${projectId}/count`,
              method: 'GET',
              params: {
                tabName,
                executiveId,
                isPartnerLead,
                ...filterPayload,
              },
            });
          })
        );

        if (results.some((result) => result.error)) {
          return {
            error: {
              status: 'CUSTOM_ERROR',
              error: 'Some Error Occurred',
            },
          };
        }
        return {
          data: leadsCount
            .map((req, index) => ({
              [req.tabName]: results[index].data,
            }))
            .reduce(
              (obj, item) => ({
                ...obj,
                ...item,
              }),
              {}
            ),
        };
      },
    }),

    getProjectLeadByLeadId: build.query<
      TProjectLead,
      { projectId: TObjectId; leadId: TObjectId }
    >({
      query: ({ projectId, leadId }) =>
        `api/projectLeads/all-leads/${projectId}/${leadId}`,
      transformResponse: (response: ITransformResponse<TProjectLead>) => {
        return response.data;
      },
    }),

    getProjectLeadByLeadIdMutation: build.mutation<
      TProjectLead,
      { projectId: TObjectId; leadId: TObjectId }
    >({
      query: ({ projectId, leadId }) => ({
        url: `api/projectLeads/all-leads/${projectId}/${leadId}`,
        method: 'GET',
      }),
      transformResponse: (response: ITransformResponse<TProjectLead>) => {
        return response.data;
      },
    }),

    expireProjectLeadAssignment: build.mutation<
      TProjectLead,
      {
        projectLeadId: TObjectId;
      }
    >({
      query: ({ projectLeadId }) => ({
        url: `api/projectLeads/expiry-cp-tagging/${projectLeadId}`,
        method: 'POST',
      }),
    }),

    getAllConflictedLeads: build.query<TConflictCp[], TObjectId>({
      query: (projectLeadId: TObjectId) =>
        `api/projectLeads/conflicted-cps/${projectLeadId}`,
      transformResponse: (response: ITransformResponse<TConflictCp[]>) => {
        return response.data;
      },
    }),

    resolveConflictedLead: build.mutation<
      TProjectLead,
      {
        projectLeadId: TObjectId;
        userId?: TObjectId;
        cpUserId: TObjectId;
      }
    >({
      query: ({ projectLeadId, userId, cpUserId }) => ({
        url: `api/projectLeads/resolve-conflicts/${projectLeadId}`,
        method: 'POST',
        body: { userId, cpUserId },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProjectLeadsByAdminUserIdQuery,
  useGetProjectCPLeadsByProjectIdQuery,
  useGetProjectSiteVisitLeadsByProjectIdQuery,
  useGetProjectVSGLeadsByProjectIdQuery,

  useGetProjectPartnerActiveLeadsMutation,

  useGetProjectPartnerSiteVisitsByProjectIdMutation,
  useGetAllProjectPartnersSiteVisitsCountQuery,
  useGetProjectPartnerSiteVisitsCountQuery,

  useGetProjectDirectSiteVisitsByProjectIdMutation,
  useGetProjectDirectSiteVisitsCountQuery,

  useGetProjectPartnerRegisteredLeadsMutation,

  useGetProjectPartnerBookedLeadsMutation,

  useGetProjectInventoryByProjectIdQuery,

  useUpdateActivetoBookedLeadsMutation,
  useUpdateSmtoUnAssignedPartnerMutation,
  useGetProjectLeadUnitsByProjectIdQuery,

  useUpdateBookedToRegistrationDoneMutation,

  useGetBookedToRegisteredLeadsMutation,

  useGetPartnerLeadsCountQuery,

  useAddProjectLeadDisputeMutation,
  useUpdateDisputeMutation,

  useAddProjectLeadMutation,
  useUpdateProjectLeadMutation,

  useGetProjectLeadFollowupsCountQuery,

  useGetProjectLeadsCountQuery,

  useGetProjectLeadByLeadIdQuery,
  useGetProjectLeadByLeadIdMutationMutation,

  useExpireProjectLeadAssignmentMutation,
  useGetAllConflictedLeadsQuery,
  useResolveConflictedLeadMutation,
} = projectLeadsAPI;

export type IGetPartnerLeadsCountReq = {
  projectId?: TObjectId;
  executiveId?: TObjectId;
  searchName?: string;
  startDate?: string;
  endDate?: string;
  tabName: string;
  isC45?: boolean;
};

export type IProjectLeadReq = {
  project: TObjectId;
  name: string;
  email: string;
  mobile: string;
  occupation?: string;
  ethnicity?: string;
  ageGroup?: string;
  source?: {
    name: string;
    subSource: string;
  };
  city?: string;
  location?: string;
};

export type IUpdateProjectLeadReq = {
  name?: string;
  email?: string;
  mobile?: string;
  occupation?: string;
  ethnicity?: string;
  ageGroup?: string;
  source?: {
    name: string;
    subSource: string;
  };
  lqr?: TObjectId;
  assignedCm?: TObjectId;
};

interface ITransformLeadUnitsResponse extends IResponseBase {
  data: IProjectUnitLead[];
}

interface ITransformUnitsResponse extends IResponseBase {
  data: IUnit[];
}

interface ITransformProjectLeadsResponse extends IResponseBase {
  data: IProjectLead[];
}
export interface ITransformActiveLeadsResponse extends IResponseBase {
  data: IActiveLead[];
}

export interface ITransformProjectPartnerSiteVisitsResponse
  extends IResponseBase {
  data: ISiteVisit[];
}

export interface ITransformDirectSiteVisitsResponse extends IResponseBase {
  data: IDirectSiteVisit[];
}

type TGetProjectLeadFollowupsCountReq = {
  projectId: TObjectId;
  tabName: TFollowupsTab;
  filterPayload: Record<string, string>;
  executiveId?: TObjectId;
}[];

type TGetProjectLeadsCountReq = {
  projectId: TObjectId;
  tabName: LEADS_TAB | PARTNER_LEAD_TABS;
  filterPayload: Record<string, string>;
  executiveId?: TObjectId;
  isPartnerLead?: boolean;
}[];

export type TConflictCp = {
  id: TObjectId;
  name: string;
  email: string;
  mobile: string;
  sm?: string;
  userId: TObjectId;
  company?: string;
  registrationDate?: string;
};
